import React,{ useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import ceostech from '../images/logonav.png'
import './navbar.css'


export default function Navbar() {
const[isMobile, setIsMobile] = useState(false);
const[loc, setLoc] = useState("accueil");
    useEffect(() => {
        console.log(loc)
    });

    return (
        
    <nav class="navbar">
        <div class ="logo">
        <button className="mobile-menu-icon"
                    onClick={() => setIsMobile(!isMobile) }
                >
                    { isMobile ? <i class="fas fa-times fa-2x"></i> : <i class="fas fa-bars fa-2x"></i> }
        </button> 
            <ul className = { isMobile ? "nav-links-mobile" : "nav-links" }
              onClick={() => setIsMobile(false) }>
                  <Link to="/.">
                  <img className="taille_imageCeos" src={ceostech} alt="ceos"/>
                  </Link>
                <Link to="/." class="accueil">
                    <li className={loc === "accueil" ? "items text-center current" : "items text-center"} onClick={(e) => setLoc("accueil")}>Accueil</li>
                </Link>
                <Link to="/qui" class="qui" onClick={(e) => setLoc("quisomme")}>
                    <li className={loc === "quisomme" ? "items text-center current" : "items text-center"}>Qui sommes-nous ? </li>
                </Link>
                <Link to="/services" class="services" onClick={(e) => setLoc("services")}>
                    <li className={loc === "services" ? "items text-center current" : "items text-center"}>Nos services</li>
                </Link>
                <Link to="/projet" class="projet">
                    <li className={loc === "projet" ? "items text-center current" : "items text-center"} onClick={(e) => setLoc("projet")}>Projets</li>
                </Link>
                <Link to="/contact" class="contact">
                    <li className={loc === "contact" ? "items text-center contacter current" : "items text-center contacter"} onClick={(e) => setLoc("contact")}>Nous contacter</li>
                </Link>
            </ul>
          
          </div>
    </nav>
    )
}


