import react from 'react';
import { Link } from 'react-router-dom';
import facebook from './images/facebook.png';
import instagram from './images/instagram.png';
import ceostech from './images/logonav.png';

function Footer() {
	return (
		<footer class="footer_hauteur my-containerf">
			<ul class=" couleurblanc espaceFooter1">
				<div class=" row justify-content-center align-items-center my-rowf mb-3 li-width">
					<Link to="/." class="contact ">
						<img className="logo-footer" src={ceostech} alt="logo do Ceos Tech" />
					</Link>
				</div>
				<div class=" row justify-content-center align-items-center my-rowf mb-3 li-width">
					<Link to="/contact" class="contact ">
						<li class="col font_sizeFooter my-colf text-center">contact@ceostech.fr</li>
					</Link>
				</div>
			</ul>
			<div class="row justify-content-center align-items-center reseau mb-3 ">
				<Link to="/facebook" class="facebook ">
					<img class=" taille_imagef" src={facebook} alt="facebook" />
				</Link>
				<Link to="/instagram" class="instagram ">
					<img class=" taille_imagef" src={instagram} alt="instagram" />
				</Link>
			</div>

			<div class="row justify-content-center align-items-center cgv">
				<ul class=" couleurgris espaceFooter2">
					<div class=" row justify-content-center align-items-center my-rowf">
						<div>
							<Link to="/copyright" class="copyright col-2 col-md-12">
								<li class="col font_sizeFooter my-colf text-center">Copyright 2022 © </li>
							</Link>
						</div>
						<div>
							<Link to="/mentions-légales" class="mentions-légales col-2 col-md-12">
								{' '}
								
								<li class="col font_sizeFooter my-colf text-center">Mentions légales</li>
							</Link>
						</div>
					</div>
				</ul>
			</div>
		</footer>
	);
}

export default Footer;
