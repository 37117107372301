import React from 'react'
import ampoule from './images/ampoule.png'
import vitrine from './images/vitrine.png'
import shopping from './images/shopping.png'
import backOffice from './images/backOffice.png'
import motivation from './images/motivation.png'
import apprentissage from './images/apprentissage.png'
import croissance from './images/croissance.png'
import shopping2 from './images/shopping2.png'
import dicton from './images/dicton.png'
import mobile from './images/mobile.png'



export default function Services3() {
    return (
        <main>
            {/* <div class="cercle1" id="pour_position5"></div> */}
            
            <sectionC1 class="containerC1 my-containerC1">
                <div class="row   justify-content-center my-rowC1 py-4">
                    <div class="col-lg-12 col-md-12 col-sm-12 my-colC1 hauteurC1 py-2">
                            <img class="card-img-top taille_imageC1 " src={ampoule} alt="ampoule"/>
                        <div class="py-4">
                            <h1 class="text-center titreC1">SERVICES</h1>
                        </div> 
                    </div>
                    <div class="row   justify-content-center my-rowC1 py-2">
                        <h2 class="text-center titreC2 "> Votre site internet et votre application mobile <br /> personnalisés. </h2>
                        
                    </div>
                </div>
            </sectionC1>
            <sectionC2 class="containerC2 my-containerC2">
                <div class="row   justify-content-center align-items-center  my-rowC2">
                    <div class="col-lg-4 col-md-8 col-sm-12 my-colC1 hauteurC2 py-5">
                        <img class="card-img-top taille_imageC2 couleurC1" src={vitrine} alt="vitrine" /> 
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 my-colC2 hauteurC3 couleurC1 py-5">
                       <h1>Votre vitrine numérique</h1>
                       <p>Le site internet, c'est la carte d'identité virtuelle de votre entreprise.
                        </p>
                        <ul>
                           <li>Améliorez vos performances commerciales</li>
                           <li>Valorisez vos services et produits</li> 
                           <li>Créez votre e-réputation.</li>
                        </ul>
                    </div>
                </div>
            </sectionC2>
            <sectionC3 class="containerC3 my-containerC3">
                <div class="row   justify-content-around align-items-center py-5 my-rowC3">
                    <div class="col-lg-6 col-md-8 col-sm-12 my-colC2 hauteurC3 ">
                       <h1 class="couleurC3">Le e-commerce à votre portée</h1>
                       <p class="text_C3a ">9 internautes sur 10 achètent en ligne. Associez votre identité virtuelle 
                           à votre boutique en ligne pour booster votre chiffre d'affaires. </p>
                    </div>
                    <div class="col-lg-2 col-md-8 col-sm-12 my-colC1 hauteurC3 ">
                        <img class="card-img-top taille_imageC3" src={shopping} alt="shopping" /> 
                    </div>
                </div>
            </sectionC3>
            <sectionC4 class="containerC4 my-containerC4">
                <div class="row   justify-content-center align-items-center  my-rowC4">
                    <div class="col-lg-4 col-md-8 col-sm-12 my-colC4 hauteurC4 py-5">
                        <img class="card-img-top taille_imageC4 couleurC4" src={backOffice} alt="backOffice" /> 
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 my-colC2 hauteurC2 couleurC4 py-5 ">
                       <h2>Un back office pour piloter votre activité</h2>
                       <p>Une interface intuitive pour gérer en toute simplicité les flux de clientèle sur votre boutique en ligne avec ses fonctionnalités complémentaires.
                        </p>
                        <ul>
                           <li>la réception de commandes</li>
                           <li>la gestion de l’activité</li> 
                           <li>l’analyse des KPI’s</li>
                        </ul>
                    </div>
                </div>
            </sectionC4>
            <sectionC5 class="container5 my-containerC5 py-4">
                <div class="row  justify-content-around align-items-center my-rowC5 py-4">
                    <div class="col-lg-3 col-md-3 col-sm-12     my-colC5 hauteurC5 shadow-lg">
                            <img class="card-img-top taille_imageC5 " src={motivation} alt="motivation"/> 
                            <p class="text-center margin_top">​Votre site internet : c'est faciliter l'échange avec 
                            vos clients et obtenir de nouveaux prospects.</p>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12     my-colC5  hauteurC5 shadow-lg">
                        <img class="card-img-top taille_imageC5" src={apprentissage} alt="apprentissage"/> 
                        <p class="card-text text-center margin_top">On détermine vos objectifs, la clientèle ciblée 
                        et on vous propose la solution adaptée à vos besoins !</p>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12     my-colC5 hauteurC5 shadow-lg">
                        <img class="card-img-top taille_imageC5" src={croissance} alt="croissance"/> 
                        <p class="card-text text-center margin_top">​L’interface administrateur sera votre bras droit 
                        et un collaborateur à part entière pour améliorer votre chiffres d’affaires !</p>
                    </div>
                </div>
            </sectionC5>
            <sectionC6 class="containerC6 my-containerC6">
                <div class="row   justify-content-center align-items-center my-row3C6">
                    <div class="col-lg-6 col-md-8 col-sm-8 my-colC6 hauteurC6">
                        <p className="texte_C6 text-center">Chez Ceos Tech, chaque client est unique !
                            Un accompagnement personnalisé et une équipe dédiée à votre projet. </p>
                        <p className="texte_T6 text-center">TPE & PME gagner en visibilité !</p> 
                    </div>
                </div>
            </sectionC6>
            <sectionC7 class=" containerC7 my-containerC7">
                <div class="row  justify-content-center align-items-center my-rowC7 bgrC7 ">
                    <div class="col-lg-8 col-md-12  my-colB2 hauteurC7">
                        <p class="text-center couleurC7">Créer votre application mobile et offrez une nouvelle 
                        expérience à vos clients</p>
                    </div>
                </div>
            </sectionC7>
            <sectionC8>
                <div class="containerC8 my-containerC8">
                    <div class="row  justify-content-center my-rowC8">
                        <div class="col-lg-6 col-md-3 col-sm-12     my-colC8 hauteurC8 text-center py-5">
                            <p class="texte_T8">Les utilisateurs favorisent leur téléphone pour satisfaire un besoin immédiat.</p>
                            <p class="texte_C8">Favorisez les achats spontanés et d'impulsion avec<br/> votre application mobile. 
                            Renforcer votre image de<br/> marque et distinguez-vous de la concurrence. </p>
                        </div>
                        <div class="col-lg-4 col-md-3 col-sm-12  texte_C8     my-colC8 hauteurC8B text-center">
                            <img class="card-img-top taille_imageC8" src={shopping2} alt="shopping"/>      
                        </div>
                         <div class="col-lg-4 col-md-3 col-sm-12  texte_C8    my-colC8  text-center">
                            <img class="card-img-top taille_imageC8" src={dicton} alt="croissance"/>      
                        </div>    
                    </div>
                </div>
            </sectionC8>
            <sectionC9>
                <div class="containerC9 my-containerC9 hauteurC9 ">
                    <div class="row  justify-content-center my-rowC9">
                        <div class="col-lg-4 col-md-3 col-sm-12  texte_C9     my-colC9  text-center">
                            <img class="card-img-top taille_imageC9" src={mobile} alt="telephone"/>      
                        </div>
                        <div class="col-lg-6 col-md-3 col-sm-12     my-colC9 text-center py-5">
                            <p class="texte_T9">Votre marque devient mobile avec votre application. Vos clients et 
                            prospects sont désormais à portée de main au quotidien.</p>

                            Créez et maintenez un lien de confiance avec vos utilisateurs ! Comme le site internet,
                            il est impératif de cibler les utilisateurs potentiels de votre application mobile et 
                            définir vos objectifs.
                        </div>
                    </div>
                </div>
            </sectionC9>
            <sectionC10>
                    <div class="containerC10 my-containerC10">
                        <div class="row  justify-content-center my-row10">
                            <div class="col-lg-3 col-md-3 col-sm-12  my-colC10 hauteurC10 text-center shadow  bg-white">
                                <h3>Native App</h3>
                                    <img class="card-img-top taille_imageC10 " src={croissance} alt="croissance"/> 
                                    <p class="text-center margin_top">Développement sur​ mesure iOS ou android.
                                    Notifications push via l'application.
                                    Disponible sur les stores et hors connexion</p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12     my-col10 hauteurC10  text-center shadow bgr10">
                                <h3>No code App</h3>
                                <img class="card-img-top taille_imageC10 " src={croissance} alt="croissance"/> 
                                <p class="card-text text-center margin_top">Aucune ligne de code nécessaire
                                    Compatible sur iOS et Android​
                                    Indisponible sur les stores et hors connexion</p>
                            </div>
                        </div>
                        <div class="row justify-content-center my-rowC10 text-center   ">
                            <div class="col-lg-3 col-md-3 col-sm-12     my-colC10 hauteur2C10 bgr10 ">
                                    <h3>Hybride App</h3>
                                    <img class="card-img-top taille_imageC10 " src={croissance} alt="croissance"/> 
                                    <p class="text-center margin_top">Code source unique 
                                        Disponible sur les stores
                                        Indisponible hors connexion</p>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12  my-colC10 hauteurC10 text-center shadow  bg-white">
                                <h3>Web App</h3>
                                <img class="card-img-top taille_imageC10" src={croissance} alt="croissance"/> 
                                <p class="text-center margin_top">Hébergement sur un serveur web
​                                          Compatible iOS et Android
                                          Indisponible hors connexion</p>
                            </div>
                        </div>
                        <div class="row  my-row3 justify-content-center align-items-center py-5">
                               <a href="/contact"><p className="texte_S "> Je veux un devis ! </p></a>  
                        </div>
                    </div>
            </sectionC10>
        </main>
    )
}


