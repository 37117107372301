import CookieConsent from 'react-cookie-consent';
import Navbar from './NavBar/Navbar'
import Footer from './Footer'
import Accueil1 from './Accueil1';
import Qui2 from './Qui2';
import Services3 from './Services3';
import Projet4 from './Projet4';
import Conctats5 from './Contacts5';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import './style1.css';
import './style2.css';


function App() {
  return (
    <Router>
        <Navbar/>
          <Routes>
              <Route path="/" element={<Accueil1/>} exact/>
              <Route path="/Qui" element={<Qui2/>} exact/>
              <Route path="/services" element={<Services3/>} exact/>
              <Route path="/projet" element={<Projet4/>} exact/>
              <Route path="/contact" element={<Conctats5/>} exact/>
          </Routes>
        <Footer/>
        <CookieConsent enableDeclineButton buttonText="J'accepte" declineButtonText="Je refuse">
				Ce site utilise des cookies pour améliorer votre expérience.
        </CookieConsent>
    </Router>
  );
}

export default App;
