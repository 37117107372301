
import accueil from './images/accueil.png'
import equipe from './images/equipe.png'
import soutien from './images/soutien.png'
import personne from './images/personne.png'
import croissance1 from './images/croissance1.png'
import liste from './images/liste.png'
import competence from './images/competence.png'
import illustration1 from './images/illustration1.png'
import illustration2 from './images/illustration2.png'
import illustration3 from './images/Illustration3.png'
import {Link} from 'react-router-dom';



export default function Accueil1() {
    return (
        <main >
            {/* <div class="cercle1" id="pour_position1"></div>
             <div class="cercle1" id="pour_position2"></div>
             <div class="cercle3" id="pour_position3"></div>
             <div class="cercle4" id="pour_position4"></div> */}
        <section1 class="section1">
                <div class="container my-container1">
                    <div class="description_ligne my-row1">
                    <div class="description">
                        <h1 class="card-title titre1">Agence de développement d'applications Web et Mobile </h1>
                        <p class="card-text texte1">Nos développeurs et nos experts sont à votre service pour vous accompagner dans la réalisation de vos projets numériques et digitaux.
                        Nous développons pour vous un site web 100 % responsive et en adéquation avec les dernières tendances numériques et digitales.
                        </p>
                        
                    <div class=" justify-content-between align-items-center  ">
                            <a href="/contact" class="bgr1">Nous contacter</a>
                        <Link to="/qui">
                            <a href="#" class="bgr1 quis">Qui sommes-nous ?</a>
                        </Link>
                    </div>
                </div>  
                    <div class="col-lg-6 col-md-8 col-sm-12 img-acceuil    my-col1 hauteur1b py-5">
                        <img class="card-img-top taille_image1 py-4" src={accueil} alt="accueil"/> 
                    </div>
                </div>
             </div>
        </section1>
        <section2>
        <div class="vos-projets">
             
                <div class="titre-projet">
                    <h1 class="text-center  title_ceos">Concrétisez vos projets</h1>
                    <p class="text-center texte1">Nous vous accompagnons en tout sérénité dans votre transformation digitale.</p>

            </div>
        </div>    
            <div class="container my-container">
                <div class="row  justify-content-center my-row2 ">
                    <div class="col-lg-3 col-md-3 col-sm-12 my-col2 hauteur2 bg-white shadow">
                        <img class="card-img-top taille_image " src={equipe} alt="equipe"/> 
                        <p class="text-center margin_top">Une équipe dédiée à votre projet</p>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12     my-col2 bgr hauteur2 shadow">
                        <img class="card-img-top taille_image" src={soutien} alt="soutien"/> 
                        <p class="card-text text-center margin_top">Un accompagnement personnalisé</p>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12   my-col2 hauteur2 shadow  bg-white ">
                        <img class="card-img-top taille_image " src={personne} alt="personne"/> 
                        <p class="card-text text-center margin_top "> Des solutions adaptées à vos besoins</p>
                    </div>
                </div>
                <div class="row justify-content-center my-row2">
                    <div class="col-lg-3 col-md-3 col-sm-12    bgr my-col2 hauteur2 shadow">
                        <img class="card-img-top taille_image " src={croissance1} alt="croissance1"/> 
                        <p class="text-center margin_top"> Boostez votre chiffre d’affaire </p>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12   my-col2 hauteur2 shadow bg-white  ">
                        <img class="card-img-top taille_image" src={liste} alt="liste"/> 
                        <p class="card-text text-center margin_top"> Développez votre portefeuille client </p>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-12     bgr my-col2 hauteur2 shadow">
                        <img class="card-img-top taille_image" src={competence} alt="competence"/> 
                        <p class="card-text text-center margin_top"> Diversifiez votre offre </p>
                    </div>
                </div>
            </div>
        </section2>
        <section3 class="container my-container3">
            <div class="row  justify-content-center align-items-center my-row3 hauteur3a">
                <div class="col-lg-12 col-md-12 col-sm-12 my-col3">
                    <h3 className="texte_C text-center ">Ceos Tech accompagne les TPE et PME dans leur</h3>
                    <h3 className="texte_T text-center "> transformation digitale</h3> 
                    <div class="row  justify-content-center align-items-center my-row3 hauteur3b">
                       <a href="/services" className="texte_S margin_top"> Nos services</a>  
                    </div>
                </div>
            </div>
        </section3>
        <section4 class=" container my-container4 pb-5 mb-5">
            <div class="row   justify-content-center align-items-center my-row4">
                <div class="col-lg-5 col-md-12 my-col4">
                    <img class="card-img-top taille_image2 text-center" src={illustration1} alt="illustration1"/> 
                </div>
                <div class="col-lg-5 col-md-12  my-col4">
                    <p class="texte_1  text-start"> Soyez acteur de votre <span class="texte_2 ">transformation digitale.</span></p>
                    <p class="texte_3"> Des outils digitaux pour
                    améliorer votre image de marque, diversifier vos canaux d'acquisition client
                    et booster votre chiffre d'affaires.</p>  
                </div>
            </div>
            <div class="row  justify-content-center align-items-center my-row4">
                 <div class="col-lg-5 col-md-12  my-col4 order-lg-1 order-md-2">
                    <p class="texte_1 card-text text-start"> Votre site internet et votre application mobile <span class="texte_2">​conçus
                    par des professionnels</span></p> 
                    <p class="texte_3">Une équipe dédiée qui vous accompagne dans toutes les étapes de la réalisation de votre projet.</p>  
                </div>
                <div class="col-lg-5 col-md-12 my-col4 order-lg-2 order-md-1">
                    <img class="card-img-top taille_image2 text-center" src={illustration2} alt="illustration2"/> 
                </div>
            </div>
            <div class="row   justify-content-center align-items-center my-row4">
                <div class="col-lg-5 col-md-12 my-col4">
                    <img class="card-img-top taille_image2 text-center" src={illustration3} alt="illustration1"/> 
                </div>
                <div class="col-lg-5 col-md-12  my-col4">
                    <p class="texte_1  text-start"> Un Besoin =  <span class="texte_2"> une Solution</span></p>
                    <p class="texte_3"> Des outils digitaux pour
                    améliorer votre image de marque, diversifier vos canaux d'acquisition client
                    et booster votre chiffre d'affaires.</p>  
                </div>
            </div>
        </section4>
        <section5 class=" container5 my-container5">
            <div class="row  justify-content-center align-items-center monFond  my-row5 ">
                <div>
                    <p className="texte_C text-start couleur_blanche">Web design, développement web, marketing, digital...</p>
                    <p className="texte_T text-start couleur_blanche ">Nous sommes à vos côtés pour monter <span>votre projet de A à Z en équipe !</span> </p> 
                    <div class="row  justify-content-center align-items-center my-row5b ">
                    <a href="/contact"><p className="texte_S5  text-center ">Nous contacter</p></a>   
                    </div>
                </div>
            </div>
        </section5>
        </main>
        
    )
}


