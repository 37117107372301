import React from 'react';
import ampoule from './images/ampoule.png';

import chiffre_un from './images/chiffre_un.png';
import Markus from './images/Markus.png';
import chiffre_deux from './images/chiffre_deux.png';
import ceostech from './images/ceostech.png';
import chiffre_trois from './images/chiffre_trois.png';

export default function Qui2() {
	return (
		<main>
			<sectionB1 class="containerB1 my-containerB1">
				<div class="row   justify-content-center align-items-center my-rowB1 py-5 ">
					<div class="col-lg-10 col-md-1 col-sm-12 my-colB1 hauteurB1 py-5">
						<img class="card-img-top taille_imageB1  " src={ampoule} alt="ampoule" />
						<div class="py-4">
							<h1 class="text-center titreB1">QUI SOMMES-NOUS ?</h1>
						</div>
					</div>
					<div class="row   justify-content-center my-rowB1 py-4" />
				</div>
				<div class="row  justify-content-around  my-rowB1">
					<div class="row  justify-content-center py-5">
						<div class="col-lg-10 col-md-6  my-colB1">
							<p class="text-center texte_BL2">
								Fort de leur expérience respective dans de nombreux domaines d’activités tels que{' '}
								<span>
									la restauration, l’entrepreneuriat ou encore la tech, les fondateurs se sont donnés
									pour mission de
								</span>{' '}
								répondre aux roblématiques de ces différents secteurs d'activité.
							</p>
						</div>
					</div>
				</div>
			</sectionB1>
			<sectionB2 class=" container5 my-containerB2">
				<div class="row  justify-content-center align-items-center hauteurB2 my-rowB2">
					<div class="col-lg-10 col-md-12  my-colB2  py-4">
						<p class="text-center texte_BB1 py-3">Vous accompagner sur la voie de l'innovation ! </p>
						<p class="text-center texte_BB2">
							L'objectif de Ceos Tech est d'accompagner les commerçants et les entrepreneurs à l'aide
							d'outils digitaux pratiques. Pour relever ces objectifs, les fondateurs ont imaginé trois
							solutions :{' '}
						</p>
					</div>
				</div>
			</sectionB2>
			<sectionBA class="containerBA my-containerBA py-5 ">
				<div class="row  my-rowBA justify-content-center align-items-center">
					<div class="col-lg-8 col-md-12 col-sm-12 my-colBA hauteurBA py-5">
						<img class="card-img-top taille_imageBB" src={chiffre_un} alt="chiffre_un" />
					</div>

					<div class="col-lg-8 col-md-12 col-sm-12 my-colBA couleurG shadow-lg">
						<img class="card-img-top taille_imageB1 " src={Markus} alt="Markus" />
						<p className="texte_D text-center">
							"MARKUS est né de l’idée d’accompagner les restaurateurs dans le pilotage de leur
							établissement et les aider à faire face aux difficultés rencontrées au quotidien"
						</p>
						<p className="texte_D text-center">
							Markus est l’outil tout-en-un permettant aux restaurateurs de gérer, de manière performante,
							leurs activité, leurs stocks et leurs ressources humaines depuis un mobile ou une tablette.
						</p>

						<p className="texte_D text-center">
							Doté d’une interface intuitive et ergonomique, MARKUS est l’assistant digital indispensable
							pour tous les restaurateurs !
						</p>

						<div class="row  my-row3 justify-content-center align-items-center py-4">
							<a href="/contact">
								<p className="texte_S"> Je découvre Markus</p>
							</a>
						</div>
					</div>
				</div>
			</sectionBA>
			<sectionBB class="containerBB my-containerBB">
				<div class="row   justify-content-center align-items-center my-rowBB">
					<div class="col-lg-10 col-md-12 col-sm-12 my-colBB hauteurBB py-5">
						<img class="card-img-top taille_imageBB" src={chiffre_deux} alt="chiffre_deux" />
					</div>
					<div class="col-lg-8 col-md-12 col-sm-12 my-colBB couleurG shadow-lg">
						<img class="card-img-top logo_qui " src={ceostech} alt="coestech" />
						<p className="texte_D text-center">
							" Partager le savoir et le rendre accessible à tous. <br /> Car la clé de la réussite c'est
							l'apprentissage"
						</p>
						<p className="texte_D text-center">
							Des offres de formation dans les domaines de la programmation informatique <br /> et de
							l'entrepreneuriat, dispensées par des professionnels qualifiés.{' '}
						</p>

						<div class="row justify-content-center align-items-center my-rowBB py-4">
							<a href="/contact">
								<p className="texte_S">Je découvre Ceos Tech formation</p>
							</a>
						</div>
					</div>
				</div>
			</sectionBB>
			<sectionBC class="containerBB my-containerBB py-3">
				<div class="row   justify-content-center align-items-center my-rowBB margin-qui">
					<div class="col-lg-8 col-md-12 col-sm-12 my-colBB hauteurBB py-5">
						<img class="card-img-top taille_imageBB" src={chiffre_trois} alt="chiffre_deux" />
					</div>

					<div class="col-lg-8 col-md-12 col-sm-12 my-colBB couleurG shadow-lg">
						<img class="card-img-top logo_qui" src={ceostech} alt="ceostech" />
						<p className="texte_D text-center">
							Des professionnels à votre écoute pour développer votre <br /> application ou site internet sur
							différentes plateformes. ​<br /> Des conseils tout au long de votre projet ainsi qu’un <br />
							accompagnement personnalisé et adapté à vos besoins. ​
						</p>

						<div class="row justify-content-center align-items-center my-rowBB py-4">
							<a href="/contact">
								<p className="texte_S">Je veux un devis ! </p>
							</a>
						</div>
					</div>
				</div>
			</sectionBC>
		</main>
	);
}
