
import ampoule from './images/ampoule.png'
import lemustang from './images/lemustang.png'
import df5 from './images/df5.png'
import legonak from './images/legonak.png'
import olokoso from './images/olokoso.png'

export default function Projet4() {
    return (
        <main>

            {/* <div class="cercle1" id="pour_position5"></div> */}

            <sectionD1 class="containerD1 my-containerC1">
                <div class="row   justify-content-center my-rowD1 py-4">
                    <div class="col-lg-12 col-md-12 col-sm-12 my-colD1 hauteurD1 py-3 ">
                        <img class="card-img-top taille_imageD1 " src={ampoule} alt="ampoule"/>
                        <div class="py-1">
                            <h1 class="text-center titreD1">PROJETS</h1>
                        </div>
                    </div>
                    <div class="row   justify-content-center my-rowD1 py-4">
                        <h2 class="text-center titreD2 ">Nos réalisations</h2>
                    </div>
                </div>
            </sectionD1>
            <sectionD2>
                <div class="containerD2 my-containerD2 ">
                    <div class="row  justify-content-around my-rowD2 hauteurD2">
                        <div class="col-lg-5 col-md-12 col-sm-12     my-colD2 ">
                            <a href="https://lemustang-bondy.fr/" target="_blank" style={{textDecoration: "none", color: "#1E91D6"}}>
                                <img class="card-img-top taille_imageD2 " src={lemustang} alt="lemustang"/> 
                                <p class="text-center bgrD2">LE MUSTANG</p>
                            </a>
                        </div>
                        <div class="col-lg-5 col-md-12 col-sm-12     my-colD2 ">
                            <a href="https://delicedf5-blancmesnil-nord.fr/" target="_blank" style={{textDecoration: "none", color: "#1E91D6"}}>
                                <img class="card-img-top taille_imageD2 " src={df5} alt="df5"/> 
                                <p class="card-text text-center bgrD2">DF5</p>
                            </a>
                        </div>
                    </div>
                    <div class="row justify-content-around my-rowD2 hauteurD2">
                        <div class="col-lg-5 col-md-12 col-sm-12     my-colD2 ">
                            <a href="https://sitegonak.web.app/" target="_blank" style={{textDecoration: "none", color: "#1E91D6"}}>
                                <img class="card-img-top taille_imageD2  " src={legonak} alt="legonak"/> 
                                <p class="text-center bgrD2">LE GONAK</p>
                            </a>
                        </div>
                        <div class="col-lg-5 col-md-12 col-sm-12     my-colD2 ">
                            <a href="https://olokososite-48316.web.app/" target="_blank" style={{textDecoration: "none", color: "#1E91D6"}}>
                                <img class="card-img-top taille_imageD2olo " src={olokoso} alt="olokoso"/> 
                                <p class="card-text text-center bgrD2olo">O’LOKOSO</p>
                            </a>
                        </div>
                    </div>
                </div>
            </sectionD2>
        </main>
    )
}


