import React from 'react'
import Navbar from './NavBar/Navbar'
import Footer from './Footer'
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ampoule from './images/ampoule.png'
import react, { useState } from 'react'
import axios from 'axios'


export default function Contacts5() {

const [ nom, setNom ]  = useState(null)
const [ prenom, setPrenom ] = useState(null)
const [ email, setEmail ]  = useState(null)
const [ telephone, setTelephone ]  = useState(null)

const [ souhait, setSouhait ]  = useState(null)
const [ projet, setProjet ]  = useState(null)
const [ image, setImage ]  = useState(null)

const ajout = async (e) => {

  e.preventDefault();

  console.log('test-bouton')
  let formField = new FormData()
  formField.append('nom',nom)
  formField.append('prenom',prenom)
  formField.append('email',email)
  formField.append('telephone', telephone)

  formField.append('souhait', souhait)
  formField.append('projet',projet)

  if(image !== null){
    formField.append('image', image)
  }
  console.log('test-bouton')
  await axios({
    method:'post',
    url:'https://ceostech.herokuapp.com/formulaire/get/',
    data:formField
  }).then(response => {
    console.log(response.data);
    // history.push('/')
  })

}
  
    return (
            <main class="container">
                {/* <div class="cercle1" id="pour_position5"></div>
                <div class="cercle5" id="pour_position10"></div>
                <div class="cercle6" id="pour_position11"></div> */}

                <sectionD3 class=" my-containerD3 ">
                    <div class="row   justify-content-center my-rowD3 py-5">
                        <div class="col-lg-12 col-md-12 col-sm-12 my-colD3 hauteurD3 py-5">
                            <img class="card-img-top taille_imageD3 " src={ampoule} alt="ampoule"/>
                            <div class="py-4">
                                <h1 class="text-center titreD31">CONTACTS</h1>
                            </div>
                        </div>
                        <div class="row   justify-content-center my-rowD3 py-2">
                            <p class="text-center titreD32 ">Vous souhaitez un devis ? Vous avez une question ? 
                            On répond à tous vos messages !</p>
                        </div>
                    </div>
                </sectionD3>
                <sectionD4>
                   <form>
                         <div class="form-row">
                           <div class="form-group col-md-6">
                             <input type="text" 
                             class="form-control inp-size shadow border-0 bg-light" 
                             id="nom"
                             name="nom"
                             value={nom}
                             placeholder="Nom"
                             onChange={(e) => setNom(e.target.value)} />
                           </div>
                           <div class="form-group col-md-6">
                             <input type="text" class="form-control inp-size shadow border-0 bg-light" 
                             id="prenom"
                             name="prenom"
                             value={prenom}
                             placeholder="Prenom"
                             onChange={(e) => setPrenom(e.target.value)} />
                           </div>
                         </div>
                         <div class="form-row">
                           <div class="form-group col-md-6">
                             <input type="text" class="form-control inp-size shadow border-0 bg-light" 
                               id="email" 
                               name="email"
                               value={email}
                               placeholder="Email"
                               onChange={(e) => setEmail(e.target.value)} />
                           </div>
                           <div class="form-group col-md-6">
                              <input type="text" class="form-control inp-size shadow border-0 bg-light" 
                              id="telephone" 
                              name="telephone"
                              value={telephone}
                              placeholder="Telephone"
                              onChange={(e) => setTelephone(e.target.value)} />
                           </div>
                           <div class="form-group col-md-12">
                              <input type="text" class="form-control inp-size shadow border-0 bg-light" 
                              id="souhait" 
                              name="souhait"
                              value={souhait}
                              placeholder="Je souhaite"
                              onChange={(e) => setSouhait(e.target.value)} />
                           </div>
                         </div>
                           <div class="form-group">
                             <textarea  class="form-control py-5 shadow border-0 bg-light" 
                             id="projet" 
                             name="projet"
                             value={projet}
                             onChange={(e) => setProjet(e.target.value)} 
                             >
                             Mon Projet</textarea>
                           </div>
                         <div class="form-group">
                           <div class="form-check">
                             <input class="form-check-input" type="checkbox" id="gridCheck"/>
                             <label class="form-check-label" for="gridCheck">
                             Je m’inscris à la newsletter de Ceos Tech pour recevoir des offres promotionnelles
                             </label>
                           </div>
                         </div>
                         {/* <div class="form-group">
                            <input type="file" class="form-control-file" 
                            name="image"
                            value={image}
                            onChange={(e)=>setImage(e.target.files[0])}/>
                        </div> */}
                         <button type="submit" onClick={ajout} class="btn btn-primary">Envoyer</button>
                    </form>
                </sectionD4>
          </main>
    )
}


